import UpIcon from '../../img/up.svg'
import DownIcon from '../../img/down.svg'
import { useStyles } from './styles';

function App() {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <img src={UpIcon} className={classes.upBranch} alt='up' />
      <div className={classes.odure}>
        <span>
          ODURE
        </span>
      </div>
      <img src={DownIcon} className={classes.bottomBranch} alt='down'/>
      <div className={classes.inDevelopment}>
        <span>
          В РАЗРАБОТКЕ
        </span>
      </div>
    </div>
  );
}

export default App;
