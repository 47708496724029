import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  '@keyframes slideRight': {
    from: {opacity: 0},
    to: {opacity: 1}
  },
  '@keyframes slideBlink': {
    '0%': {opacity: 0},
    '50%': {opacity: 1},
    '100%': {opacity: 0}
  },
  container: {
    animationName: '$slideRight'
  },
  app:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    flexDirection: 'column',
    backgroundColor: '#2e2c2d'
  },
  odure:{
    color: '#DDA131',
    fontSize: '300px',
    animation: '3s ease-in $slideRight'
  },
  upBranch: {
    marginBottom: '40px',
    animation: '3s ease-in $slideRight'
  },
  bottomBranch: {
    animation: '3s ease-in $slideRight'
  },
  inDevelopment: {
    color: '#DDA131',
    fontSize: '100px',
    animation: '3s ease-in 3s infinite $slideBlink',
    opacity: 0
  },
  [`@media (max-width: 768px)`]: {
    app: {
      
    },
    odure: {
      fontSize: '100px'
    },
    upBranch: {
      marginBottom: '13px',
      width: '150px'
    },
    bottomBranch: {
      width: '150px'
    },
    inDevelopment: {
      fontSize: '33px',
    }
  }
});